<template>

  <div class="workflow__details service_form_details">
    <b-row
      class="content-header"
    >

      <!-- Content Left -->
      <b-col
        class="content-header-left mb-2"
        cols="12"
        md="8"
      >
        <b-row class="breadcrumbs-top">
          <b-col cols="12">
            <h1 class="custom-header-title">
              {{ announcement.title }}
            </h1>
            <p class="log_info">
              Created by {{ announcement.createdBy ? announcement.createdBy.name : '' }} on {{ dateFormatWithTime(announcement.createdAt) }} <br>Last updated on {{ dateFormatWithTime(announcement.updatedAt) }}
            </p>
          </b-col>
        </b-row>
      </b-col>

      <b-col
        class="content-header-right text-md-right d-md-block d-none mb-1"
        md="4"
        cols="12"
      >
        <div class="user_block ac_type">
          <div class="user_avatar" />
          <div class="user_info">
            <h6>Status</h6>
            <h5 class="text-capitalize">
              <span :class="getClass(announcement.status)">{{ announcement.status ? (announcement.status === 'pending' ? 'pending verification' : announcement.status) : '' }}</span>
            </h5>
          </div>
          <div class="action_button">
            <b-button
              v-if="canViewThisAction('update', 'Announcement')"
              variant="flat-primary"
              @click="showModalOrWarning"
            >
              <feather-icon
                icon="EditIcon"
                class="mr-50"
                size="16"
              />
            </b-button>
          </div>
        </div>
      </b-col>

      <!-- Content Right -->

    </b-row>
    <!-- Table Container Card -->
    <b-alert
      variant="primary"
      show
    >
      <div class="alert-body">
        <span><strong>For Nirvana Agent SG</strong> This is the control panel where administrators can edit the content for the agent-facing mobile app.</span>
      </div>
    </b-alert>
    <b-card
      class="mb-3 px-3 py-2 calllog-details"
    >
      <b-card-title class="custom-header-title fs-21 mb-1">
        <b-row>
          <b-col
            class="mb-1"
            md="6"
            cols="12"
          >
            Details
          </b-col>
          <b-col
            class="content-header-right text-md-right d-md-block d-none mb-1"
            md="6"
            cols="12"
          >
            <b-button
              variant="link"
              class="text-danger"
              @click="showDeleteWarning"
            >
              <feather-icon
                icon="Trash2Icon"
                class="mr-50 text-danger"
                size="16"
              />
              <span class="align-middle">Delete</span>
            </b-button>
            <b-button
              variant="link"
              class="text-primary"
              :to="{ name: 'agencies-announcements-edit', params: { id: $route.params.id } }"
            >
              <feather-icon
                icon="EditIcon"
                class="mr-50"
                size="16"
              />
              <span class="align-middle">Edit</span>
            </b-button>
          </b-col>
        </b-row>
      </b-card-title>
      <b-table-simple
        borderless
        responsive
      >
        <b-tbody>
          <b-tr>
            <b-th width="230">
              Title
            </b-th>
            <b-td class="mt-50">
              {{ announcement.title || '-' }}
            </b-td>
          </b-tr>
          <b-tr>
            <b-th width="230">
              Category
            </b-th>
            <b-td class="mt-50">
              <span
                v-for="(cat, catIndex) in announcement.categories"
                :key="catIndex"
              >
                {{ cat.name }}<span v-if="announcement.categories.length != catIndex + 1">, </span>
              </span>
            </b-td>
          </b-tr>
          <b-tr>
            <b-th width="230">
              Push to
            </b-th>
            <b-td class="mt-50">
              <span
                v-for="(user, userIndex) in announcement.to"
                :key="userIndex"
              >
                {{ user.name }}<span v-if="announcement.to.length != userIndex + 1">, </span>
              </span>
            </b-td>
          </b-tr>
          <b-tr>
            <b-th width="230">
              Published By
            </b-th>
            <b-td class="mt-50">
              <span
                v-for="(dept, deptIndex) in announcement.departments"
                :key="deptIndex"
              >
                {{ dept.name }}<span v-if="announcement.departments.length != deptIndex + 1">, </span>
              </span>
            </b-td>
          </b-tr>
          <b-tr>
            <b-th width="230">
              Show in Highlights?
            </b-th>
            <b-td class="mt-50">
              {{ announcement.highlight ? 'Yes' : 'No' }}
            </b-td>
          </b-tr>
          <template v-if="announcement.highlight">
            <b-tr>
              <b-th width="230">
                Highlight Image
              </b-th>
              <b-td class="mt-50">
                <b-img
                  class="mb-1 mb-sm-0 user__avatar img-preview-block wh-100"
                  :src="announcement.image ? announcement.image : defaultImage"
                />
              </b-td>
            </b-tr>
            <b-tr>
              <b-th width="230">
                Start Date and Time
              </b-th>
              <b-td class="mt-50">
                {{ announcement.fromDate ? dateFormatWithTime(announcement.fromDate) : '-' }}
              </b-td>
            </b-tr>
            <b-tr>
              <b-th width="230">
                End Date and Time
              </b-th>
              <b-td class="mt-50">
                {{ announcement.toDate ? dateFormatWithTime(announcement.toDate) : '-' }}
              </b-td>
            </b-tr>
          </template>
          <b-tr>
            <b-th width="230">
              Content
            </b-th>
            <b-td class="mt-50">
              <!-- eslint-disable -->
              <div
                v-html="announcement.content"
                class="rich-text-modify"
              />
              <!-- eslint-enable -->
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>

      <hr>

      <div class="overview__option-result-block">
        <div class="overview__option-result-budget-amount card-statistics statistics-body my-2">
          <b-row>
            <b-col md="2">
              <b-media
                no-body
                class="m-25"
              >
                <b-media-aside
                  class="mr-1"
                >
                  <b-avatar
                    size="48"
                    variant="light-success"
                  >
                    <feather-icon
                      size="24"
                      icon="SendIcon"
                    />
                  </b-avatar>
                </b-media-aside>
                <b-media-body class="my-auto">
                  <h4 class="font-weight-bolder mb-0">
                    {{ (announcement.sent && announcement.sent > 0) ? announcement.sent : '--' }}
                  </h4>
                  <b-card-text class="font-small-3 mb-0">
                    Sent
                  </b-card-text>
                </b-media-body>
              </b-media>
            </b-col>
            <b-col md="2">
              <b-media
                no-body
                class="m-25"
              >
                <b-media-aside
                  class="mr-1"
                >
                  <b-avatar
                    size="48"
                    variant="light-warning"
                  >
                    <feather-icon
                      size="24"
                      icon="BookOpenIcon"
                    />
                  </b-avatar>
                </b-media-aside>
                <b-media-body class="my-auto">
                  <h4 class="font-weight-bolder mb-0">
                    {{ (announcement.opened && announcement.opened > 0) ? announcement.opened : '--' }}
                  </h4>
                  <b-card-text class="font-small-3 mb-0">
                    Opened
                  </b-card-text>
                </b-media-body>
              </b-media>
            </b-col>
            <b-col md="2">
              <b-media
                no-body
                class="m-25"
              >
                <b-media-aside
                  class="mr-1"
                >
                  <b-avatar
                    size="48"
                    variant="light-info"
                    class="modify-avatar"
                  >
                    <feather-icon
                      size="24"
                      icon="EyeIcon"
                    />
                  </b-avatar>
                </b-media-aside>
                <b-media-body class="my-auto">
                  <h4 class="font-weight-bolder mb-0">
                    {{ (announcement.read && announcement.read > 0) ? `${announcement.read} Agents (${ (announcement.read / announcement.sent) * 100 }%)` : '--' }}
                  </h4>
                  <b-card-text class="font-small-3 mb-0">
                    Read
                  </b-card-text>
                </b-media-body>
              </b-media>
            </b-col>
          </b-row>
        </div>
      </div>
      <span>Download the report to view the complete list of recipients who have and have not read the announcement.</span>
      <br>
      <b-button
        variant="outline-primary"
        class="mb-75 mt-2"
        disabled
      >
        <feather-icon
          icon="DownloadIcon"
          class="mr-50"
          size="16"
        />
        <span class="align-middle">Download Report (CSV)</span>
      </b-button>
    </b-card>

    <b-card
      class="mb-3 mt-3 p-2"
    >
      <b-row
        class="content-header"
      >
        <!-- Content Left -->
        <b-col
          class="content-header-left mb-2 p-2"
          cols="12"
          md="12"
        >
          <h4>
            <feather-icon
              icon="ListIcon"
              class="mr-50"
              size="16"
            />
            <span class="align-middle">Activity Logs</span>
          </h4>
        </b-col>
      </b-row>
      <app-timeline>
        <!-- FINANCIAL REPORT -->
        <app-timeline-item
          v-for="(opt, key) in activityLogs"
          :key="key"
          :variant="getVariant(key)"
        >

          <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
            <h6>{{ opt.title }}</h6>
            <small class="text-muted">{{ dateFormatWithTime(opt.time) }}</small>
          </div>
          <b-button
            v-if="opt.activityDetails && opt.activityDetails.length"
            v-b-toggle="'report-list-with-icon-' + key"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            size="sm"
            variant="outline-primary"
          >
            Details
            <feather-icon
              icon="ChevronUpIcon"
              class="ml-50 when-open"
              size="16"
            />
            <feather-icon
              icon="ChevronDownIcon"
              class="ml-50 when-closed"
              size="16"
            />
          </b-button>
          <b-collapse
            v-if="opt.activityDetails && opt.activityDetails.length"
            :id="'report-list-with-icon-' + key"
          >
            <b-table-simple
              borderless
              responsive
            >
              <b-tbody>
                <b-tr
                  v-for="(activityDetail, activitykey) in opt.activityDetails"
                  :key="activitykey"
                >
                  <b-th style="width: 300px; max-width: 300px;">
                    {{ activityDetail.title }}
                  </b-th>
                  <!-- eslint-disable-next-line vue/no-v-html -->
                  <b-td v-html="activityDetail.oldValue" />
                  <!-- eslint-disable-next-line vue/no-v-html -->
                  <b-td v-html="activityDetail.newValue" />
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </b-collapse>
        </app-timeline-item>
      </app-timeline>
    </b-card>

    <b-modal
      id="announcement-all-modal-update-status"
      ref="update-status-modal"
      cancel-variant="flat-primary"
      modal-class="status-update-modal sign-modal-adjust"
      ok-variant="success"
      ok-title="Update"
      cancel-title="Cancel"
      centered
      no-close-on-backdrop
      title="Update Status"
      @ok="submitForm"
    >
      <b-form @submit.prevent="submitForm">
        <validation-observer
          ref="announcementStatusEditForm"
        >
          <b-form-group
            label="Status*"
            label-for="h-event-status"
          >
            <validation-provider
              #default="{ errors }"
              name="Status"
              vid="status"
              rules="required"
            >
              <v-select
                id="h-event-status"
                v-model="status"
                label="title"
                :options="statusDataOptions"
                :reduce="title => title.code"
                :clearable="false"
                @input="statusValidation == true ? statusValidation = false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
              <small
                v-if="statusValidation"
                class="text-danger"
              >
                {{ statusError }}
              </small>
            </validation-provider>
          </b-form-group>
        </validation-observer>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard, BRow, BButton, BCol, BTableSimple, BTbody, BTr, BTh, BTd, VBTooltip, BAlert, BImg, BCardTitle, BForm, BFormGroup,
  VBToggle, BCollapse, BMedia, BMediaAside, BAvatar, BMediaBody, BCardText,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import store from '@/store/index'
import vSelect from 'vue-select'
import { required } from '@validations'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import { useUtils as useAclUtils } from '@/libs/acl/custom'

const { canViewThisAction } = useAclUtils()

export default {
  components: {
    BCard,
    BRow,
    BButton,
    BCol,
    BTableSimple,
    BTbody,
    BTr,
    BTh,
    BTd,
    BImg,
    BAlert,
    BCardTitle,
    BForm,
    BFormGroup,
    BCollapse,
    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,
    BCardText,

    vSelect,
    ValidationProvider,
    ValidationObserver,

    AppTimeline,
    AppTimelineItem,
  },
  directives: {
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      // eslint-disable-next-line global-require
      defaultImage: require('@/assets/images/placeholder/placeholder_image.png'),
      topSpacing: 135,
      canViewThisAction,
      announcement: {},
      status: 'published',
      statusValidation: false,
      statusError: 'Valid status is required',
      statusDataOptions: [
        { title: 'Draft', code: 'draft' },
        { title: 'Published', code: 'published' },
        { title: 'Unpublished', code: 'unpublished' },
        { title: 'Pending Verification', code: 'pending' },
      ],
      activityLogs: [],
      required,
    }
  },
  created() {
    this.$http.get(`customer/announcements/${this.$route.params.id}/show`)
      .then(response => {
        document.title = `${response.data.title} | ${process.env.VUE_APP_APP_NAME || 'Nirvana Memorial Garden'}`
        this.announcement = response.data || {}
        this.activityLogs = this.announcement.activityLog.sort((a, b) => new Date(b.time) - new Date(a.time))
        // this.makeHoverItems()
        store.commit('breadcrumb/REMOVE_BREADCRUMB')
        const newMeta = {
          active: true,
          text: `${this.announcement.title}`,
        }
        store.commit('breadcrumb/UPDATE_BREADCRUMB', newMeta)
      })
      .catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  },

  methods: {
    showDeleteWarning() {
      this.$swal({
        title: 'Delete this Announcement?',
        html: 'Are you sure you want to delete this announcement? This cannot be undone.',
        // eslint-disable-next-line global-require
        imageUrl: require('@/assets/images/icons/delete-yellow.png'),
        reverseButtons: true,
        showCancelButton: true,
        cancelButtonText: 'No, go back',
        confirmButtonText: 'Yes, delete',
        customClass: {
          confirmButton: 'btn btn-primary ml-1',
          cancelButton: 'btn btn-outline-primary',
        },
        buttonsStyling: false,
      })
        .then(async result => {
          if (result.value) {
            this.$http
              .delete(`customer/announcements/${this.$route.params.id}/delete`)
              .then(() => {
                this.$router.push({ name: 'agencies-announcements-index', params: { type: 'all-announcement' } })
              })
              .catch(error => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              })
          }
        })
    },
    getClass(status) {
      if (status === 'published') return 'text-success'
      if (status === 'draft') return 'text-secondary'
      if (status === 'unpublished') return 'text-danger'
      if (status === 'pending') return 'text-warning'
      return 'primary'
    },
    getVariant(key) {
      if (key % 4 === 0) return 'info'
      if (key % 4 === 1) return 'warning'
      if (key % 4 === 2) return 'danger'
      if (key % 4 === 3) return 'purple'
      return 'primary'
    },
    showModalOrWarning() {
      this.$root.$emit('bv::show::modal', 'announcement-all-modal-update-status', '')
    },
    submitForm() {
      this.$refs.announcementStatusEditForm.validate().then(success => {
        if (success) {
          const formData = new FormData()
          formData.append('status', this.status)

          this.$http.patch(`customer/announcements/${this.$route.params.id}/status/update`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          })
            .then(response => {
              this.announcement.status = response.data.data.status || 'pending'
              this.activityLogs = response.data.data.activityLog.sort((a, b) => new Date(b.time) - new Date(a.time))
              this.$swal({
                title: 'Status Updated!',
                html: `The status for <strong>${this.announcement.title}</strong> has been updated`,
                // eslint-disable-next-line global-require
                imageUrl: require('@/assets/images/icons/save.png'),
                imageWidth: 80,
                imageHeight: 80,
                imageAlt: 'Custom Icon',
                showCancelButton: false,
                confirmButtonText: 'Okay',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            })
            .catch(error => {
              if (error.data.errors) {
                error.data.errors.forEach(validationError => {
                  if (validationError.param === 'status') {
                    this.statusError = validationError.msg
                    this.statusValidation = true
                  }
                })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              }
            })
        }
      })
    },
  },
}
</script>
